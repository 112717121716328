/* Public styles */

body{
    background-color: black !important;
    color: white !important;
}

.addrow {
    display: flex;
}

.addcol {
    flex: 100%;
}

.addcol1 {
    flex: 70%;
}

.addcol2 {
    margin-left:20px;
    flex: 30%;
}

.magic{
    background-color: #333333;
    border-radius: 10px;
    padding:10px;
}

h4{
    color: #cccccc;
}

#root{
    height: 100%;
}

.version{
    color: #cccccc;
}

footer{
    color: #cccccc !important;
    background-color: #333333 !important;
}

footer a{
    color: lightskyblue;
}

footer a:hover{
    color: lightskyblue;
}

.footer-text{
    font-size: small !important;;
}

.footer-col-left, .footer-col-right {
    float: left;
    width: 50%;
}

/* Clear floats after the columns */
.footer-row:after{
    content: "";
    display: table;
    clear: both;
    margin-bottom: 10px;
}
.footer-col-right{
    text-align: right;
}

.container-footer{
    padding: 0 20px;
}

.bg-dark{
    background-color: black !important;
}

.container-fluid{
    width:95% !important;
}

a{
     /*color: #0c64ad;*/
     color: lightskyblue;
     text-decoration: none !important;
 }

a:hover{
    color: lightskyblue;
    text-decoration: underline !important;

}

h2{
    color: #cccccc;
}

.slick h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 300px !important;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}

.slick .item, .slick-detail .item, .slick-detail-small .item {
    background: #666666;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
    border-radius: 20px;
    background-size: cover;
}
.slick-detail {
    width: 95%;
    margin: auto;
}

.slick .item {
    height: 250px !important;
    font-size: medium;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.slick-detail .item {
    height: 200px !important;
    font-size: small;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.slick-detail-small .item {
    height: 150px !important;
    font-size: small;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.slick-detail-header{
    color:#999999;
    font-size:medium;
    margin-left:20px;
}

.item-overlay{
    background-color: rgba(0,0,0,0.6);
    border-radius: 20px;

}

.slick a, .slick a:hover, .slick-detail a, .slick-detail a:hover, .slick-detail-small a, .slick-detail-small a:hover{
    text-decoration: none !important;
    color: white;
}

.slick .item-title-slick{
    color: lightskyblue;
    font-size: x-large;
}

.slick-detail .item-title-slick{
    color: lightskyblue;
    font-size: large;
}

.slick-detail-small .item-title-slick{
    color: lightskyblue;
    font-size: medium;
}

.item-desc-slick{
    margin-top: 15px;
}

.item-type-slick{
    font-weight: bold;
}

.slick-dots li button::before, .slick-next::before, .slick-prev::before{
    color: white !important;
}

.slick-dots li.slick-active button::before{
    color: white !important;
}

.container{
    margin-bottom: 50px;
}

.home-text{
    width: 75%;
}

.home-login{
    float:right;
    margin-left: 2%;
    width: 24%;
    background-color: #333333;
    border-radius: 10px;
    padding: 10px
}

.login-register, .profile{
    margin-bottom: 10px;
}

.profile{
    width: 100%;
}

.error{
    font-weight: bold;
    color: orangered;
}
.success{
    color: limegreen;
}
.message{
    font-weight: bold;
    color: orange;
    margin-bottom: 20px;
}
.message a{
    color: lightskyblue;
}


/* social colors */

.color-facebook, .color-channel_facebook {
    color: #3b5999;
    fill: #3b5999;
}

.color-twitter, .color-channel_twitter {
    color: #55acee;
    fill: #55acee;
}

.color-instagram, .color-channel_instagram {
    color: #f82877;
    fill: #f82877;
}

.color-researchgate, .color-channel_researchgate {
    color: #40ba9b;
    fill: #40ba9b;
}

.color-github, .color-channel_github {
    color: black;
    fill: black;
}

.color-vkontakte, .color-channel_vkontakte {
    color: #2787f5;
    fill: #2787f5;
}

.color-telegram, .color-channel_telegram {
    color: #0088cc;
    fill: #0088cc;
}

.add_entry{
    margin-top:20px;
}
.add_entry_opt{
    color:lightskyblue;
    font-size:medium;
}

.add_entry_req{
    color: lightskyblue;
    font-size:xx-large;
}


/* Network Graph Styling */

svg .node-group {
    font-size: 0.7rem;
    /* color: var(--bs-body-color); */
}

svg .Organization {
    /* indigo-300 */
    fill: #a370f7;
}


svg .Entry {
    /* indigo-300 */
    fill: #a370f7;
}

svg .NewsSource {
    /* green-300 */
    /* fill: #75b798; */
}

svg .currentNode {
    fill: var(--bs-orange)!important;
}

svg .color-website, svg .color-channel_selfhostedwebsite {
    /* green-300 */
    fill: #75b798;
}

svg .color-print, svg .color-channel_print {
    /* green-300 */
    fill: var(--bs-gray);
}

svg .node-group:hover {
    font-size: 1.1rem;
}

svg .node-group:hover circle {
    stroke: lightskyblue;
}

svg .node-group:hover .Organization {
    fill: var(--bs-indigo);
}

svg .node-group:hover .NewsSource {
    /* fill: var(--bs-green); */
}

svg .node-group:hover .color-website {
    fill: var(--bs-green);
}

svg .text-decoration-none {
    text-decoration: none!important;
}

.tab_panel{
    margin-top:10px;
}

@media all and (max-width: 1000px) {
    .home-login{
        width: 200px;
    }
}
@media all and (max-width: 800px) {
    .home-login{
        display: none;
    }
    .home-text{
        width: 100%;
    }
}

@media all and (max-width: 850px) {
    .slick-detail {
        width: 92%;
    }
}

@media all and (max-width: 650px) {
    .slick-detail-small {
        width: 95%;
        margin: auto;
    }
    .slick-detail {
        width: 90%;
    }
}