/* m3 tokens */

:root {
    --md-sys-color-primary: black;

    /* Circular Progress */
    --md-circular-progress-active-indicator-color: white;
    --md-circular-progress-size: 32px;

    /* Outlined Button */
    --md-outlined-button-container-shape: 5px;
    --md-outlined-button-label-text-font: system-ui;
    --md-outlined-button-hover-label-text-color: white;
    --md-outlined-button-hover-state-layer-color: #0e68b9;
    --md-outlined-button-hover-state-layer-opacity: 100;
    --md-outlined-button-outline-color: #0e68b9;
    --md-outlined-button-label-text-color: white;

    /* Filled Button */
    --md-filled-button-container-shape: 5px;
    --md-filled-button-label-text-font: system-ui;
    --md-filled-button-container-color: #0e68b9;
    --md-filled-button-label-text-color: white;
    --md-filled-button-hover-label-text-color: white;
    --md-filled-button-hover-state-layer-color: darkblue;
    --md-filled-button-hover-state-layer-opacity: 100;

    /* Text Button */
    --md-text-button-container-shape: 5px;
    --md-text-button-label-text-font: system-ui;
    --md-text-button-hover-label-text-color: white;
    --md-text-button-pressed-label-text-color: white;
    --md-text-button-hover-state-layer-color: darkblue;
    --md-text-button-hover-state-layer-opacity: 100;
    --md-text-button-outline-color: #0e68b9;
    --md-text-button-label-text-color: white;

    --md-text-button-disabled-label-text-color: white;
    --md-text-button-disabled-label-text-opacity: white;
    --md-text-button-focus-label-text-color: white;

    /* Filled Text Field  */
    --md-filled-text-field-container-shape: 5px;
    --md-sys-color-surface-container-highest: white;
    --md-filled-text-field-label-text-color: #3f4948;
    --md-filled-text-field-input-text-color: #161d1d;
    --md-filled-text-field-input-text-font: system-ui;
    --md-filled-text-field-label-text-font: system-ui;
    --md-filled-text-field-focus-active-indicator-height: 0;
    --md-filled-text-field-top-space: 7px;
    --md-filled-text-field-bottom-space: 7px;

    /* Radio buttons */
    --md-radio-icon-color: grey;
    --md-radio-hover-icon-color: white;
    --md-radio-selected-icon-color: white;
    --md-radio-pressed-icon-color: white;
    --md-radio-focus-icon-color: white;
    --md-radio-selected-focus-icon-color: white;

    /* checkbox buttons */
    --md-checkbox-focus-outline-color: white;
    --md-checkbox-hover-outline-color: white;
    --md-checkbox-hover-state-layer-color: white ;
    --md-checkbox-hover-state-layer-opacity: 50%;
    --md-checkbox-outline-color: white;
    --md-checkbox-pressed-outline-color: white;
    --md-checkbox-pressed-state-layer-color: white;
    --md-checkbox-pressed-state-layer-opacity: 50%;
    --md-checkbox-selected-container-color: lightskyblue;
    --md-checkbox-selected-focus-container-color: lightskyblue;
    --md-checkbox-selected-focus-icon-color: white;
    --md-checkbox-selected-hover-container-color: lightskyblue;
    --md-checkbox-selected-hover-icon-color: white;
    --md-checkbox-selected-hover-state-layer-color: white;
    --md-checkbox-selected-hover-state-layer-opacity: 50%;
    --md-checkbox-selected-icon-color: white;
    --md-checkbox-selected-pressed-container-color: lightskyblue;
    --md-checkbox-selected-pressed-icon-color: white;
    --md-checkbox-selected-pressed-state-layer-color: white;
    --md-checkbox-selected-pressed-state-layer-opacity: 50%;

    /* Dialog */
    --md-dialog-container-color: lightskyblue;
    --md-dialog-headline-color: black;
    --md-dialog-headline-font: system-ui;
    --md-dialog-supporting-text-color: black;
    --md-dialog-supporting-text-font: system-ui;

    /* Tabs */
    --md-primary-tab-label-text-font: system-ui;
    --md-primary-tab-container-color: black;
    --md-primary-tab-hover-label-text-color: lightskyblue;
    --md-primary-tab-pressed-label-text-color: lightskyblue;
    --md-primary-tab-active-label-text-color: white;
    --md-primary-tab-active-hover-label-text-color: white;
    --md-primary-tab-active-pressed-label-text-color: white;
    --md-primary-tab-active-indicator-color: white;
    --md-primary-tab-label-text-color: #666666;
    --md-primary-tab-label-text-weight: bold;
    --md-divider-color: black;


}

.m3_icon{
    vertical-align: bottom;
}

