/* DivTable.com */

.divHeader{
    width: 100%;
    margin-bottom: 10px;
    background-color: #333333;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.divTableHeader{
    display: table;
    width: 70%;
    float: left;
}

.divTable{
    display: table;
    width: 100%;
    margin-bottom: 10px;
    background-color: #333333;
    padding: 10px;
    border-radius: 10px;
}
.divTableSlick{
    width: 100%;
    margin-bottom: 10px;
    background-color: #333333;
    padding: 10px;
    border-radius: 10px;
}
.divTableRow {
    display: table-row;
}
.divTableRowImg {
    display: block;
    float: right;
}
.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}
.divTableCell, .divTableCellTitle, .divTableHead, .divTableCellSubText {
    border: 0px solid #999999;
    display: table-cell;
    padding: 2px 0px;
}

.divTableHead{
    color: #cccccc;
    width: 200px;
}
.divTableCell, .divTableCellTitle{
    font-weight: bold;
}
.divTableCellTitle{
    font-size: large;
}
.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}
.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}
.divTableBody {
    display: table-row-group;
}
h3{
    color: #ffffff;
}
.link_list {
    padding-right: 10px;
}
.divTable a{
    color: lightskyblue;
}
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.item-img{
    float:right;
}
.reduced, .divTableCellSubText{
    font-size: 80%;
    color: #eee;
}
.full_text{
    border-radius: 5px;
    background-color: lightskyblue;
    color: black;
    font-weight: bold;
    padding:5px;
    margin-left:10px;
    font-size:90%;
}

.divDetailHeader{
    width: 70%;
    float: left;
}

.divDetailRow {
    display: flex;
}

.divDetailColumn {
    flex: 50%;
    background-color: #666;
    color: white;
    padding:10px;
    margin:10px;
    border-radius: 10px;
    min-height: 70px;
}

.divDetailHeadValue{
    font-size: 120%;
}

.divDetailHeadKey{
    font-size: 85%;
    color: #ddd;
}

.weekdayOff, .weekdayOn {
    padding:5px;
    width: 40px;
    border-radius: 20px;
    margin-right: 5px;
    float: left;
    text-align: center;
    vertical-align: center;
}

.weekdayOff{
    color: #ccc;
    font-weight: normal;
    background-color: #999999;
}

.weekdayOn{
    font-weight: bold;
    background-color: lightskyblue;
    color: black;
}
.widget{

}

img svg  {
    height: auto;
    width: 100%;
}

@media all and (max-width: 1000px) {
    .divDetailHeader{
        width:100%;
    }
    .home-text{
        width: 100%;
    }
}